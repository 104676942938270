import MarketService from "@/API/services/Management/MarketService";

const state = {
  markets: []
};
const getters = {};
const actions = {
  createMarket(_, data) {
    return MarketService.createMarket(data);
  },
  updateMarket(_, { id, data }) {
    return MarketService.updateMarket(id, data);
  },
  getMarket(_, id) {
    return MarketService.getMarket(id);
  },
  getMarkets({ commit }) {
    return new Promise((resolve, reject) => {
      MarketService.getMarkets()
        .then(response => {
          resolve(response);
          commit("setMarkets", response.data.markets);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
const mutations = {
  setMarkets(state, markets) {
    state.markets = markets;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
