import { authenticationHeader, getHttpClient } from "@/API/httpClient";

const httpClient = getHttpClient();

export default {
  uploadContentFile(file, documentType) {
    return httpClient.post(`/contentr/upload-file/${documentType}`, file, {
      headers: {
        ...authenticationHeader(),
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });
  },
  getProjects() {
    return httpClient.get(`/contentr/list`, {
      headers: {
        ...authenticationHeader()
      }
    });
  },
  getProject(contentrProjectId) {
    return httpClient.get(`/contentr/${contentrProjectId}`, {
      headers: {
        ...authenticationHeader()
      }
    });
  },
  findStream(contentrProjectId, payload) {
    return httpClient.post(
      `/contentr/${contentrProjectId}/stream`,
      {
        ...payload
      },
      {
        headers: {
          ...authenticationHeader()
        }
      }
    );
  },
  findWidgets(contentrProjectId, payload) {
    return httpClient.post(`/contentr/${contentrProjectId}/widgets`, payload, {
      headers: {
        ...authenticationHeader()
      }
    });
  },
  deleteProject(contentrProjectId) {
    return httpClient.delete(`/contentr/${contentrProjectId}`, {
      headers: authenticationHeader()
    });
  }
};
