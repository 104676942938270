import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import vueMoment from "vue-moment";
import moment from "moment-timezone";
// prototypes
import { EventBus } from "@/prototypes/EventBus";
import { notifier } from "@/prototypes/notifier";
import { modals } from "@/prototypes/modals";
import { initializationUserAuthentication } from "@/API/Auth";

Vue.prototype.$EventBus = EventBus;
Vue.prototype.$notifier = notifier;
Vue.prototype.$modals = modals;
Vue.config.productionTip = false;

Vue.use(vueMoment, {
  moment
});

const initAuth = async () => {
  await initializationUserAuthentication();
  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
  }).$mount("#app");
};

initAuth();
