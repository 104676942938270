import ProjectService from "@/API/services/ProjectService";

const state = {
  lastCallParams: null,
  projects: [],
  drafts: [],
  project: null
};
const getters = {
  getProjects: ({ projects }) => projects,
  getDrafts: ({ drafts }) => drafts,
  getProject: ({ project }) => project
};
const actions = {
  getTags({ commit }, { brandwatchProjectId, brandwatchQueryId, marketId }) {
    commit("setLastCallParams", { brandwatchQueryId, brandwatchProjectId });
    return ProjectService.getTags(
      brandwatchProjectId,
      brandwatchQueryId,
      marketId
    );
  },
  async updateProjectStatus({ commit, dispatch, state }, projectId) {
    await dispatch("getProject", projectId);
    if (state.projects.length < 1) {
      await dispatch("getProjects", projectId);
    }
    commit("updateProjectStatus");
  },
  getProjects({ commit }) {
    return new Promise((resolve, reject) => {
      ProjectService.getProjects()
        .then(response => {
          resolve(response);
          commit("setProjects", response.data.projects);
          commit("setDrafts", response.data.drafts);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  getProject({ commit }, projectId) {
    return new Promise((resolve, reject) => {
      ProjectService.getProject(projectId)
        .then(response => {
          resolve(response);
          commit("setProject", response.data);
          commit("updateProjectStatus");
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  getProcessingStatus({ commit }, projectId) {
    commit("setLastCallParams", { projectId });
    return ProjectService.getProcessingStatus(projectId);
  },
  deleteProject(_, id) {
    return new Promise((resolve, reject) => {
      ProjectService.deleteProject(id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
const mutations = {
  setLastCallParams(state, params) {
    state.lastCallParams = params;
  },
  setProjects(state, projects) {
    state.projects = projects;
  },
  setDrafts(state, drafts) {
    state.drafts = drafts;
  },
  setProject(state, project) {
    state.project = project;
  },
  updateProjectStatus(state) {
    state.projects.map(project => {
      if (project.projectId === state.project._id) {
        project.status = state.project.state.status;
      }
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
