import DashboardService from "@/API/services/DashboardService";

export const metricsPreviewFactory = () => ({
  columns: [],
  rows: []
});

const state = {
  metricsPreview: metricsPreviewFactory()
};
const getters = {
  getBarData: ({ barData }) => barData,
  getGridData: ({ gridData }) => gridData,
  getTableData: ({ tableData }) => tableData,
  getDonutData: ({ donutData }) => donutData,
  getDataTablePreview: ({ metricsPreview }) => metricsPreview
};
const actions = {
  getBarData(_, { projectId, kind }) {
    return DashboardService.getBarData(projectId, kind);
  },
  getGridData(_, { projectId, x, y }) {
    return DashboardService.getGridData(projectId, x, y);
  },
  getTableData(_, { projectId }) {
    return DashboardService.getTableData(projectId);
  },
  exportXlsxDataTable(_, { projectId }) {
    DashboardService.exportXlsxDataTable(projectId).then(response => {
      console.log({ response });

      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute("download", "correlation-table.xlsx");
      document.body.appendChild(fileLink);

      this.isOpen = false;
      fileLink.click();
    });
  },
  getDonutData(_, { projectId }) {
    return DashboardService.getDonutData(projectId);
  }
};
const mutations = {
  setDataTablePreview(state, metrics) {
    state.metricsPreview = metrics;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
