export default [
  {
    path: "/panel/markets/new",
    name: "PanelMarketsNewMarket",
    meta: {
      humanName: "New Market"
    },
    component: () =>
      import(
        /* webpackChunkName: "panel-markets-new-market" */ "@/views/Panel/views/Markets/NewMarket.vue"
      )
  },
  {
    path: "/panel/markets/:id",
    name: "PanelMarketsMarket",
    meta: {
      humanName: "Market"
    },
    component: () =>
      import(
        /* webpackChunkName: "panel-markets-market" */ "@/views/Panel/views/Markets/Market.vue"
      )
  }
];
