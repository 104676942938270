export function copyTextToClipboard(text, id = "clipboard-holder") {
  const cb = document.getElementById(id);
  cb.value = text;
  cb.style.display = "block";
  cb.style.zIndex = "99999";
  cb.select();
  console.log(cb.value);
  document.execCommand("copy");
  cb.style.display = "none";
}

export function roundValue(value, digits) {
  return parseFloat(value).toFixed(digits);
}

export function localize(value, country) {
  return value.toLocaleString(country);
}

export function chunkArray(arr, size) {
  return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );
}

export function nonUniqBy(array, iteratee) {
  return array.filter(item1 => {
    if (
      array.filter(item2 => {
        return item1[iteratee] === item2[iteratee];
      }).length > 1
    ) {
      return item1;
    }
  });
}
