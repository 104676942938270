export const stateArrayManager = ({
  arrayName,
  set,
  add,
  update,
  remove,
  id = "localId"
}) => {
  const manager = {};

  manager[set] = (state, items) => {
    state[arrayName] = items;
  };
  manager[add] = (state, item) => {
    state[arrayName].push(item);
  };
  manager[update] = (state, item) => {
    state[arrayName] = state[arrayName].map(oldItem => {
      if (oldItem[id] === item[id]) {
        return { ...oldItem, ...item };
      }

      return oldItem;
    });
  };
  manager[remove] = (state, item) => {
    state[arrayName] = state[arrayName].filter(
      oldItem => oldItem[id] !== item[id]
    );
  };

  return manager;
};
