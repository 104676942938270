<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <v-spacer />
          <v-tooltip v-if="copyContent" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" @click="copy">
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("COMMON.TOOLTIP.COPY_CONTENT") }}</span>
          </v-tooltip>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <input id="clipboard-holder" type="text" hidden />
        <v-card-text>
          {{ content }}
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { copyTextToClipboard } from "@/helpers/helpers";

export default {
  name: "InformativeModal",
  data() {
    return {
      dialog: false,
      content: "",
      copyContent: false
    };
  },
  mounted() {
    this.$EventBus.$on("informative-modal-action", this.handleModal);
  },
  beforeDestroy() {
    this.$EventBus.$off("informative-modal-action", this.handleModal);
  },
  methods: {
    handleModal(modal) {
      this.dialog = modal.open;
      this.content = modal.content;
      this.copyContent = modal.copyContent;
    },
    copy() {
      copyTextToClipboard(this.content);
      this.$notifier.notify({
        message: this.$t("COMMON.NOTIFICATIONS.TEXT_COPIED")
      });
      setTimeout(() => {
        this.dialog = false;
      }, 500);
    }
  }
};
</script>
