import MiscService from "@/API/services/MiscService";

const state = {
  countries: [],
  languages: [],
  markets: []
};
const getters = {};
const actions = {
  getCountries({ commit }) {
    return new Promise((resolve, reject) => {
      MiscService.getCountries()
        .then(response => {
          commit("setCountries", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getLanguages({ commit }) {
    return new Promise((resolve, reject) => {
      MiscService.getLanguages()
        .then(response => {
          commit("setLanguages", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getMarkets({ commit }) {
    return new Promise((resolve, reject) => {
      MiscService.getMarkets()
        .then(response => {
          commit("setMarkets", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
const mutations = {
  setCountries(state, countries) {
    state.countries = countries;
  },
  setLanguages(state, languages) {
    state.languages = languages;
  },
  setMarkets(state, markets) {
    state.markets = markets;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
