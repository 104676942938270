import { get, has } from "lodash";
import { checkTokenValidity, getAccessToken } from "@/API/Auth";
import store from "@/store";

export function beforeGuard(to, from, next) {
  const isLogged = checkTokenValidity(getAccessToken());
  const preventAuthorizedUsers =
    has(to, "meta.preventAuthorizedUsers") &&
    get(to, "meta.preventAuthorizedUsers");

  if (isLogged && !preventAuthorizedUsers) {
    next();
  }

  if (isLogged && preventAuthorizedUsers) {
    next({ name: "Home" });
  }

  if (!isLogged && preventAuthorizedUsers) {
    next();
  }

  if (!isLogged && !preventAuthorizedUsers) {
    next({ name: "Login" });
  }

  if (Object.keys(to.meta).includes("roles")) {
    if (!to.meta.roles.includes(store.state.auth.user.role)) {
      next({ name: "Home" });
    }
  }

  next();
}
