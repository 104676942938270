import user from "@/store/modules/API/modules/Management/user";
import market from "@/store/modules/API/modules/Management/market";

export default {
  namespaced: true,
  modules: {
    user,
    market
  }
};
