import { authenticationHeader, getHttpClient } from "@/API/httpClient";

const httpClient = getHttpClient();

export default {
  getCountries() {
    return httpClient.get("/misc/countries", {
      headers: authenticationHeader()
    });
  },
  getLanguages() {
    return httpClient.get("/misc/languages", {
      headers: authenticationHeader()
    });
  },
  getMarkets() {
    return httpClient.get("/misc/markets", {
      headers: authenticationHeader()
    });
  }
};
