import KnowledgeBaseService from "@/API/services/KnowledgeBaseService";

const state = {};
const getters = {};
const actions = {
  getProjectsKnowledgeBase(_, params) {
    return KnowledgeBaseService.getProjectsKnowledgeBase(params);
  },
  getProject(_, projectId) {
    return KnowledgeBaseService.getProject(projectId);
  }
};
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
