import ContentrService from "@/API/services/ContentrService";

const state = {
  projects: [],
  areProjectsLoading: false
};
const getters = {
  getProjects: ({ projects }) => projects
};
const actions = {
  uploadContenterFile(_, { file, documentType }) {
    return ContentrService.uploadContentFile(file, documentType);
  },
  getProjects({ commit }) {
    commit("setProjectsLoading", true);
    return new Promise((resolve, reject) => {
      ContentrService.getProjects()
        .then(response => {
          resolve(response);
          commit("setProjects", response.data.projects);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit("setProjectsLoading", false);
        });
    });
  },
  getProject(_, contentrProjectId) {
    return ContentrService.getProject(contentrProjectId);
  },
  findStream(_, { contentrProjectId, payload }) {
    console.log({ payload });
    return ContentrService.findStream(contentrProjectId, payload);
  },
  findWidgets(_, { contentrProjectId, payload }) {
    return ContentrService.findWidgets(contentrProjectId, payload);
  },
  deleteProject(_, contentrProjectId) {
    return ContentrService.deleteProject(contentrProjectId);
  }
};
const mutations = {
  setProjects(state, projects) {
    state.projects = projects;
  },
  setProjectsLoading(state, loading) {
    state.areProjectsLoading = loading;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
