<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="480px" persistent>
      <v-card>
        <v-card-title class="border-top-error">
          <div class="mx-auto">{{ title }}</div>

          <v-btn icon @click="handleCancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          {{ content }}
        </v-card-text>
        <v-card-actions>
          <div class="mx-auto">
            <v-btn text @click="handleCancel">{{ $t("COMMON.CANCEL") }}</v-btn>
            <v-btn color="error" outlined @click="handleApply">{{
              $t("COMMON.APPLY")
            }}</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "ConfirmationModal",
  data() {
    return {
      dialog: false,
      content: "test",
      title: "test",
      action: null,
      cancelAction: null
    };
  },
  mounted() {
    this.$EventBus.$on("confirmation-modal-action", this.handleModal);
  },
  beforeDestroy() {
    this.$EventBus.$off("confirmation-modal-action", this.handleModal);
  },
  methods: {
    handleModal(modal) {
      this.dialog = modal.open;
      this.content = modal.content;
      this.title = modal.title;
      this.action = modal.action;
      this.cancelAction = modal.cancelAction;
    },
    handleApply() {
      this.dialog = false;

      if (typeof this.action === "function") {
        this.action();
      } else {
        console.warn(
          "Confirmation Modal action is not a function:",
          this.action
        );
      }
    },
    handleCancel() {
      this.dialog = false;
      if (typeof this.cancelAction === "function") {
        this.cancelAction();
      }
    }
  }
};
</script>
