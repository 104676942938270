import { notifier } from "@/prototypes/notifier";

const state = {
  countryDictionary: []
};
const getters = {
  getCountryDictionary: ({ countryDictionary }) => countryDictionary,
  getCountryDictionaryLength: ({ countryDictionary }) =>
    countryDictionary.length
};
const actions = {
  requestCountryDictionary({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      dispatch("api/topicModeling/getAvailableLanguages", null, {
        root: true
      })
        .then(response => {
          commit("setCountryDictionary", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          notifier.notify({
            message: "Cannot get dictionaries",
            color: "error"
          });
        });
    });
  }
};
const mutations = {
  setCountryDictionary(state, dictionary) {
    state.countryDictionary = dictionary;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
