import { authenticationHeader, getHttpClient } from "@/API/httpClient";

const httpClient = getHttpClient();

export default {
  run(data) {
    return httpClient.post(`/topic-modeling/run`, data, {
      headers: authenticationHeader()
    });
  },
  getMetrics(projectId) {
    return httpClient.get(`/topic-modeling/${projectId}/get-metrics`, {
      headers: authenticationHeader()
    });
  },
  saveValues(projectId, data) {
    return httpClient.post(
      `/topic-modeling/${projectId}/save-values`,
      { metrics: data },
      {
        headers: authenticationHeader()
      }
    );
  },
  getAvailableLanguages() {
    return httpClient.get("/topic-modeling/available-languages", {
      headers: authenticationHeader()
    });
  },
  uploadContentFile(projectId, file) {
    return httpClient.post(`/topic-modeling/upload-file`, file, {
      headers: {
        ...authenticationHeader(),
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });
  }
};
