export const contentrProjectFactory = () => ({
  from: 0,
  size: 20,
  query: "*",
  excludeQuery: null,
  onlyStories: false,
  startDate: null,
  endDate: null,
  sort: "desc",
  sortBy: "Date"
});

export const widgetsFactory = () => ({
  hashtag_bigram: [],
  hashtag_unigram: [],
  phrase_bigram: [],
  phrase_bigram_seq: [],
  phrase_trigram_seq: [],
  phrase_unigram: [],
  timeline: [],
  total: 0
});

const stateFactory = () => ({
  isStreamLoading: false,
  isStreamLoadingMore: false,
  areWidgetsLoading: false,
  initCallParams: {
    contentrProjectId: null,
    payload: contentrProjectFactory()
  },
  lastResponse: [],
  stream: [],
  widgets: widgetsFactory()
});

const state = {
  ...stateFactory()
};
const getters = {};
const actions = {
  getData({ commit, dispatch }, { contentrProjectId, payload }) {
    commit("setIsStreamLoading", true);
    commit("setAreWidgetsLoading", true);
    commit("setInitCallParams", { contentrProjectId, payload });
    commit("setStream", []);
    commit("setWidgets", widgetsFactory());

    dispatch(
      "api/contentr/findStream",
      { contentrProjectId, payload },
      { root: true }
    )
      .then(response => {
        commit("addStream", response.data);
        commit("setLastResponse", response.data);
      })
      .catch(error => {
        console.log(error);
        // notify about error
      })
      .finally(() => {
        commit("setIsStreamLoading", false);
      });

    dispatch(
      "api/contentr/findWidgets",
      { contentrProjectId, payload },
      { root: true }
    )
      .then(response => {
        commit("setWidgets", response.data);
      })
      .catch(error => {
        console.log(error);
        // notify about error
      })
      .finally(() => {
        commit("setAreWidgetsLoading", false);
      });
  },
  filter({ state, commit, dispatch }, { filters }) {
    const { contentrProjectId, payload } = state.initCallParams;

    commit("setIsStreamLoading", true);
    commit("setStream", []);
    commit("setFilters", filters);
    commit("resetStreamInitCallParams");

    dispatch(
      "api/contentr/findStream",
      { contentrProjectId, payload: { ...payload, ...filters } },
      { root: true }
    )
      .then(response => {
        commit("addStream", response.data);
        commit("setLastResponse", response.data);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        commit("setIsStreamLoading", false);
      });
  },
  loadMore({ state, commit, dispatch }) {
    const { contentrProjectId, payload } = state.initCallParams;

    if (state.lastResponse.length < state.initCallParams.payload.size) {
      return;
    }

    commit("setIsStreamLoadingMore", true);
    dispatch(
      "api/contentr/findStream",
      {
        contentrProjectId,
        payload: {
          ...payload,
          from: state.stream.length + state.lastResponse.length
        }
      },
      { root: true }
    )
      .then(response => {
        commit("addStream", response.data);
        commit("setLastResponse", response.data);
      })
      .catch(error => {
        console.log(error);
        // notify about error
      })
      .finally(() => {
        commit("setIsStreamLoadingMore", false);
      });
  }
};
const mutations = {
  setIsStreamLoading(state, loading) {
    state.isStreamLoading = loading;
  },
  setIsStreamLoadingMore(state, loading) {
    state.isStreamLoadingMore = loading;
  },
  setAreWidgetsLoading(state, loading) {
    state.areWidgetsLoading = loading;
  },
  setStream(state, stream) {
    state.stream = stream;
  },
  addStream(state, stream) {
    state.stream.push(...stream);
  },
  setWidgets(state, widgets) {
    state.widgets = widgets;
  },
  setInitCallParams(state, params) {
    state.initCallParams = params;
  },
  resetStreamInitCallParams(state) {
    state.initCallParams.payload.size = 20;
    state.initCallParams.payload.from = 0;
  },
  setLastResponse(state, response) {
    state.lastResponse = response;
  },
  setFilters(state, filters) {
    state.initCallParams.payload.sort = filters.sort;
    state.initCallParams.payload.sortBy = filters.sortBy;
  },
  clearState(state) {
    const initialState = stateFactory();
    Object.keys(initialState).forEach(key => {
      state[key] = initialState[key];
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
