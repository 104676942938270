<template>
  <v-snackbar
    v-model="snackbar"
    :bottom="toast.y === 'bottom'"
    :color="toast.color"
    :left="toast.x === 'left'"
    :right="toast.x === 'right'"
    :timeout="toast.timeout"
    :top="toast.y === 'top'"
  >
    {{ toast.message }}

    <template v-slot:action="{ attrs }">
      <v-btn dark text v-bind="attrs" @click="snackbar = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "Notifier",
  data() {
    return {
      snackbar: false,
      toast: {
        message: "",
        color: "info",
        timeout: "6000",
        x: "right",
        y: "bottom"
      }
    };
  },
  mounted() {
    this.$EventBus.$on("notifier-notify", this.handleToast);
  },
  beforeDestroy() {
    this.$EventBus.$off("notifier-notify", this.handleToast);
  },
  methods: {
    handleToast(toast) {
      this.snackbar = true;
      this.toast = toast;
    }
  }
};
</script>
