import AuthService from "@/API/services/AuthService";
import { notifier } from "@/prototypes/notifier";
import {
  checkTokenValidity,
  getRefreshToken,
  getTimeDiff,
  removeLocalStorageTokens,
  setLocalStorageTokens,
  setLoginToken,
  setUser
} from "@/API/Auth";
import jwtDecode from "jwt-decode";

const state = {
  user: null,
  tokenRefreshCounter: null,
  auth: null,
  isAuthorized: false
};
const getters = {
  getUser: ({ user, auth }) => {
    if (!user || !auth) {
      return {
        _id: null,
        email: null,
        fullname: null,
        role: null,
        default_language: null,
        markets: {
          main: [],
          available: []
        }
      };
    }

    return user;
  }
};
const actions = {
  login({ dispatch, commit }, credentials) {
    return new Promise((resolve, reject) => {
      AuthService.login(credentials)
        .then(async response => {
          const { user } = response.data;
          await dispatch("authorize", response.data);
          setLoginToken(credentials.token);
          setUser(user);
          commit("setUser", user);
          resolve(response);
        })
        .catch(error => {
          notifier.notify({
            message: "Ops! Something went wrong.",
            color: "error"
          });
          reject(error);
        });
    });
  },
  async authorize({ commit, dispatch }, tokens) {
    const accessTokenValid = checkTokenValidity(tokens && tokens.accessToken);
    commit("setIsAuthorized", accessTokenValid);
    if (accessTokenValid) {
      commit("setAuth", tokens);
      await setLocalStorageTokens(tokens);
    }
    return dispatch("refreshToken");
  },
  getNewRefreshToken({ dispatch }) {
    const BEARER = "Bearer";

    return new Promise(resolve => {
      return AuthService.refreshToken({
        refreshToken: `${BEARER} ${getRefreshToken()}`
      })
        .then(async response => {
          await dispatch("authorize", response.data);
          return resolve();
        })
        .catch(error => {
          console.log(error);
          dispatch("logout");
        });
    });
  },
  async refreshToken({ commit, dispatch, state }) {
    await dispatch("clearTimeoutToken");
    const renewalTimeBuffer = 2000;
    const timeDiff = getTimeDiff(state && state.auth && state.auth.exp);
    let timeoutCount =
      renewalTimeBuffer < timeDiff ? timeDiff - renewalTimeBuffer : timeDiff;
    if (process.env.NODE_ENV === "development") {
      console.log({ timeDiff, timeoutCount });
    }
    if (timeoutCount) {
      const renewalTimeout = setTimeout(() => {
        dispatch("getNewRefreshToken");
      }, timeoutCount);
      commit("setTokenRefreshCounter", renewalTimeout);
    } else {
      await dispatch("getNewRefreshToken");
    }
  },
  async logout({ commit }) {
    commit("setUser", null);
    commit("setIsAuthorized", false);
    removeLocalStorageTokens();
    window.location.pathname = "/login";
  },
  clearTimeoutToken({ commit, state }) {
    clearTimeout(state.tokenRefreshCounter);
    commit("setTokenRefreshCounter", null);
  }
};
const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setTokenRefreshCounter(state, counter) {
    state.tokenRefreshCounter = counter;
  },
  setAuth(state, tokens) {
    const decodeAccessToken = jwtDecode(tokens && tokens.accessToken);
    state.auth = {
      accessToken: (tokens && tokens.accessToken) || "",
      refreshToken: (tokens && tokens.refreshToken) || "",
      sub: (decodeAccessToken && decodeAccessToken.sub) || "",
      rol: (decodeAccessToken && decodeAccessToken.rol) || "",
      iat: (decodeAccessToken && decodeAccessToken.iat) || "",
      exp: (decodeAccessToken && decodeAccessToken.exp) || ""
    };
  },
  setIsAuthorized(state, isAuth) {
    state.isAuthorized = isAuth;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
