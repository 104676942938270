import UserService from "@/API/services/Management/UserService";

const state = {
  users: []
};
const getters = {};
const actions = {
  createUser(_, credentials) {
    return UserService.createUser(credentials);
  },
  updateUser(_, { id, data }) {
    return UserService.updateUser(id, data);
  },
  getUser(_, id) {
    return UserService.getUser(id);
  },
  getUsers({ commit }) {
    return new Promise((resolve, reject) => {
      UserService.getUsers()
        .then(response => {
          resolve(response);
          commit("setUsers", response.data.users);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  regenerateToken(_, id) {
    return UserService.regenerateToken(id);
  }
};
const mutations = {
  setUsers(state, users) {
    state.users = users;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
