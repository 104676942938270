<template>
  <div class="d-flex align-center justify-center">
    <v-toolbar-title v-if="showActions" class="d-flex">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            elevation="0"
            v-bind="attrs"
            height="48"
            v-on="on"
            class="rounded-r-0"
            :loading="isLoading"
            :disabled="isLoading"
            @click="handleCreateDraft"
          >
            <v-icon small class="mr-4">mdi-plus</v-icon>
            Build a new project
          </v-btn>
        </template>
        <span>Build project for selected market</span>
      </v-tooltip>
      <v-select
        v-model="select"
        :loading="isLoading"
        :disabled="isLoading || user.markets.available.length < 2"
        :items="user.markets.available"
        class="rounded-l-0"
        flat
        hide-no-data
        hide-details
        item-text="name"
        style="max-width: 120px;"
        :menu-props="{ bottom: true, offsetY: true }"
        item-value="_id"
        solo
        background-color="primary lighten-1"
        dark
      />
    </v-toolbar-title>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "BaseHeaderActions",
  data() {
    return {
      select: null,
      search: null,
      isLoading: false
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    showActions() {
      return ![
        "Project",
        "LanguageAudit",
        "LanguageAuditUpload",
        "ContentrIndex",
        "ContentrUpload",
        "ContentrProject"
      ].includes(this.$route.name);
    }
  },
  created() {
    this.select = this.user.markets.main[0]._id;
  },
  methods: {
    ...mapActions({
      createDraft: "api/draft/createDraft",
      getProjects: "api/project/getProjects"
    }),
    handleCreateDraft() {
      this.isLoading = true;
      this.createDraft(this.select)
        .then(async ({ data }) => {
          await this.getProjects();
          await this.$router.push({
            name: "Project",
            params: {
              projectId: data.draftId,
              draft: "draft"
            }
          });
        })
        .catch(() => {
          this.$notifier.notify({
            message: this.$t("PROJECT.CREATE_NEW_ERROR"),
            color: "error"
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>
