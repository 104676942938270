import { authenticationHeader, getHttpClient } from "@/API/httpClient";

const httpClient = getHttpClient();

export default {
  getProjectsKnowledgeBase(
    params = {
      limitPerPage: 10
    }
  ) {
    return httpClient.post(
      "/knowledge-base",
      { filters: params },
      {
        headers: authenticationHeader()
      }
    );
  },
  getProject(projectId) {
    return httpClient.get(`/knowledge-base/${projectId}`, {
      headers: authenticationHeader()
    });
  }
};
