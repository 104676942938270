import { dataSources } from "@/store/modules/baseConfiguration";

export function resolveAvailableTabs(selectedDataSources) {
  const tabs = [
    {
      text: "Brand list",
      component: "BrandList",
      tabComponent: "BrandCard",
      order: 0
    }
  ];

  const resolver = {
    TOPICS_FROM_LANGUAGE_AUDIT: {
      text: "Topic list",
      component: "TopicList",
      tabComponent: "TopicCard",
      order: 1
    },
    EXTERNAL_BRANDS: {
      text: "External brands",
      component: "ExternalBrandsList",
      tabComponent: "ExternalBrandCard",
      order: 2
    }
  };

  selectedDataSources.forEach(source => {
    if (Object.keys(resolver).includes(source)) {
      tabs.push(resolver[source]);
    }
  });

  return tabs.sort((a, b) => (a.order > b.order ? 1 : -1));
}

export function prepareSaveProjectRequest(
  {
    brands,
    topics,
    selectedDataSources,
    externalBrands,
    brandwatchQueryString
  },
  { name, startDate, endDate }
) {
  const pulsarTabs = ["api", "manual"];
  const dummyDataSources = {};

  Object.keys(dataSources).forEach(source => {
    const key = source
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
    dummyDataSources[key] = selectedDataSources.includes(source);
  });

  externalBrands = externalBrands.file;
  selectedDataSources = dummyDataSources;
  brands.map(brand => {
    if (pulsarTabs[brand.pulsar.currentTab] === "manual") {
      for (const metric in brand.pulsar.manual) {
        for (const social in brand.pulsar.manual[metric]) {
          brand.pulsar.manual[metric][social] = brand.pulsar.manual[metric][
            social
          ]
            ? parseFloat(brand.pulsar.manual[metric][social])
            : 0.0;
        }
      }
    }

    if (pulsarTabs[brand.pulsar.currentTab] === "api") {
      const platformsWithUrls = Object.keys(brand.pulsar.api).filter(
        key => brand.pulsar.api[key].url
      );
      brand.pulsar.api = Object.keys(brand.pulsar.api)
        .filter(key => platformsWithUrls.includes(key))
        .reduce((obj, key) => {
          obj[key] = brand.pulsar.api[key];
          return obj;
        }, {});
    }

    brand.pulsar = {
      [pulsarTabs[brand.pulsar.currentTab]]:
        brand.pulsar[pulsarTabs[brand.pulsar.currentTab]]
    };

    brand.topics = Object.keys(brand.topics ? brand.topics : {}).map(
      topicId => ({
        name: topics.find(({ localId }) => localId === topicId).name,
        value: parseFloat(brand.topics[topicId])
      })
    );

    brand.sites = brand.sites.filter(site => site.url);

    return brand;
  });

  return {
    name,
    startDate,
    endDate,
    brands,
    selectedDataSources,
    externalBrands,
    brandwatchQueryString
  };
}
