<template>
  <v-container fluid style="max-width: 1785px" class="pa-0">
    <v-toolbar flat>
      <div
        class="d-flex align-center"
        @click="$root.$emit('open-navigation-menu')"
      >
        <v-app-bar-nav-icon />
        <span class="mr-5 text-uppercase" style="cursor: pointer;">
          Menu
        </span>
      </div>
      <v-divider vertical />
      <router-link
        class="d-flex align-center text-decoration-none"
        :to="{ name: 'Home' }"
      >
        <base-header-logo />
        <base-header-page-title />
      </router-link>
      <v-spacer />
      <base-header-actions />
    </v-toolbar>
  </v-container>
</template>
<script>
import BaseHeaderLogo from "@/components/Header/components/BaseHeaderLogo";
import BaseHeaderActions from "@/components/Header/components/BaseHeaderActions";
import BaseHeaderPageTitle from "@/components/Header/components/BaseHeaderPageTitle";
export default {
  name: "BaseHeader",
  components: {
    BaseHeaderPageTitle,
    BaseHeaderLogo,
    BaseHeaderActions
  }
};
</script>

<style lang="scss">
.header-container {
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  margin: 0 auto;
  max-width: 1280px;
}
</style>
