export default [
  {
    path: "/panel/users/new",
    name: "PanelUsersNewUser",
    meta: {
      humanName: "New User"
    },
    component: () =>
      import(
        /* webpackChunkName: "panel-users-new-user" */ "@/views/Panel/views/Users/NewUser.vue"
      )
  },
  {
    path: "/panel/user/:id",
    name: "PanelUsersUser",
    meta: {
      humanName: "User"
    },
    component: () =>
      import(
        /* webpackChunkName: "panel-users-user" */ "@/views/Panel/views/Users/User.vue"
      )
  }
];
