<template>
  <div class="d-flex">
    <div class="text-h5">BAV Social</div>
    <!--    todo: to musi stad isc precz !!<div-->
    <!--      class="caption grey&#45;&#45;text"-->
    <!--      v-if="project && $route.name === 'Dashboard'"-->
    <!--    >-->
    <!--      Start: {{ project.startDate }} End: {{ project.endDate }}-->
    <!--    </div>-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "BaseHeaderPageTitle",
  computed: {
    ...mapGetters({
      project: "api/project/getProject"
    })
  }
};
</script>
