<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="480px" persistent>
      <v-card>
        <v-card-title class="border-top-error">
          <div class="mx-auto">{{ title }}</div>

          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          {{ content }}
        </v-card-text>
        <v-card-actions>
          <div class="mx-auto">
            <v-btn outlined text @click="dialog = false">{{
              $t("COMMON.OK")
            }}</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "ValidationModal",
  data() {
    return {
      dialog: false,
      content: "",
      title: ""
    };
  },
  mounted() {
    this.$EventBus.$on("validation-modal-action", this.handleModal);
  },
  beforeDestroy() {
    this.$EventBus.$off("validation-modal-action", this.handleModal);
  },
  methods: {
    handleModal(modal) {
      this.dialog = modal.open;
      this.content = modal.content;
      this.title = modal.title;
    }
  }
};
</script>
