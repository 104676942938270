import { authenticationHeader, getHttpClient } from "@/API/httpClient";

const httpClient = getHttpClient();

export default {
  createUser(credentials) {
    return httpClient.post("/management/user/create", credentials, {
      headers: authenticationHeader()
    });
  },
  updateUser(id, data) {
    return httpClient.patch(`/management/user/${id}`, data, {
      headers: authenticationHeader()
    });
  },
  getUser(id) {
    return httpClient.get(`/management/user/${id}`, {
      headers: authenticationHeader()
    });
  },
  getUsers() {
    return httpClient.get("/management/users", {
      headers: authenticationHeader()
    });
  },
  regenerateToken(id) {
    return httpClient.patch(`/management/user/${id}/regenerate-token`, null, {
      headers: authenticationHeader()
    });
  }
};
