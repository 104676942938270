import project from "@/store/modules/API/modules/project";
import topicModeling from "@/store/modules/API/modules/topicModeling";
import draft from "@/store/modules/API/modules/draft";
import management from "@/store/modules/API/modules/Management";
import misc from "@/store/modules/API/modules/misc";
import knowledgeBase from "@/store/modules/API/modules/knowledgeBase";
import contentr from "@/store/modules/API/modules/contentr";

export default {
  namespaced: true,
  modules: {
    project,
    topicModeling,
    draft,
    management,
    misc,
    knowledgeBase,
    contentr
  }
};
