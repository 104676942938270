import DraftService from "@/API/services/DraftService";

const state = {};
const getters = {};
const actions = {
  createDraft(_, marketId) {
    return new Promise((resolve, reject) => {
      DraftService.createDraft(marketId)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  getDraft(_, draftId) {
    return new Promise((resolve, reject) => {
      DraftService.getDraft(draftId)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  deleteDraft(_, draftId) {
    return new Promise((resolve, reject) => {
      DraftService.deleteDraft(draftId)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  updateDraft(_, { draftId, data }) {
    return new Promise((resolve, reject) => {
      DraftService.updateDraft(draftId, data)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  },
  uploadBrands(_, file) {
    return new Promise((resolve, reject) => {
      DraftService.uploadBrands(file)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  searchBrand(_, term) {
    return new Promise((resolve, reject) => {
      DraftService.searchBrand(term)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
