import axios from "axios";
import * as Auth from "@/API/Auth";
import store from "@/store";
import * as config from "./../../config.json";

export const getHttpClient = (baseURL = config.API_BASE_URL) => {
  const config = {
    baseURL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  };

  const httpClient = axios.create(config);

  httpClient.interceptors.response.use(
    response => response,
    async error => {
      const { status } = error.response;
      if (status === 401) {
        await store.dispatch("auth/logout", null, { root: true });
      }
      return Promise.reject(error);
    }
  );

  return httpClient;
};

export const authenticationHeader = () => {
  return {
    Authorization: `Bearer ${Auth.getAccessToken()}`
  };
};
