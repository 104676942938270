import { v4 as uuidv4 } from "uuid";

export const stateFactory = () => ({
  currentDraftData: null,
  currentTab: null,
  currentlyEditedBrand: null,
  currentlyEditedTopic: null,
  selectedDataSources: [],
  pulsars: {},
  brands: [],
  topics: [],
  externalBrands: {
    brands: [],
    file: null
  },
  brandwatchQueryString: null,
  brandFormRef: null
});

export const projectSettingsFactory = () => ({
  name: null,
  startDate: null,
  endDate: null
});

export const brandDataFactory = () => ({
  localId: uuidv4(),
  name: "",
  wasSaved: false,
  tagId: null,
  bavOffline: null,
  sites: [
    {
      url: null,
      geo: null
    }
  ],
  pulsar: {
    currentTab: 0
  }
});

export const pulsarApiFactory = () => ({
  platformFacebook: {
    url: null,
    mode: "regional",
    source: "facebook"
  },
  platformTwitter: {
    url: null,
    mode: "regional",
    source: "twitter"
  },
  platformInstagram: {
    url: null,
    mode: "regional",
    source: "instagram"
  }
});

export const pulsarManualFactory = () => ({
  comments: {
    fb: null,
    twitter: null,
    instagram: null
  },
  likes: {
    fb: null,
    twitter: null,
    instagram: null
  },
  shares: {
    fb: null,
    twitter: null
  },
  sum: {
    video: null,
    photo: null,
    text: null
  }
});

export const topicDataFactory = () => ({
  localId: uuidv4(),
  name: "",
  wasSaved: false
});
