import MetricService from "@/API/services/MetricService";

export const metricsPreviewFactory = () => ({
  metrics: [],
  pillars: [],
  brands: []
});

const state = {
  metricsPreview: metricsPreviewFactory()
};
const getters = {
  getMetricsPreview: ({ metricsPreview }) => metricsPreview
};
const actions = {
  getMetrics({ commit }, projectId) {
    return new Promise((resolve, reject) => {
      MetricService.getMetrics(projectId)
        .then(response => {
          commit("setMetricsPreview", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          console.log(error);
        });
    });
  },
  recalculateMetrics({ commit }, { projectId, data }) {
    return new Promise((resolve, reject) => {
      MetricService.recalculateMetrics(projectId, data)
        .then(response => {
          commit("setMetricsPreview", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          console.log(error);
        });
    });
  },
  recalculateAndSaveMetrics({ commit, dispatch }, { projectId, data }) {
    return new Promise((resolve, reject) => {
      MetricService.recalculateAndSaveMetrics(projectId, data)
        .then(async response => {
          await dispatch("api/project/updateProjectStatus", projectId, {
            root: true
          });
          commit("setMetricsPreview", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          console.log(error);
        });
    });
  },
  saveMetrics(_, projectId) {
    return MetricService.saveMetrics(projectId);
  },
  getBrandwatchMetrics(_, projectId) {
    return MetricService.getBrandwatchMetrics(projectId);
  },
  saveBrandwatchMetrics({ dispatch }, { projectId, data }) {
    return new Promise((resolve, reject) => {
      MetricService.saveBrandwatchMetrics(projectId, data)
        .then(async response => {
          await dispatch("api/project/updateProjectStatus", projectId, {
            root: true
          });
          resolve(response);
        })
        .catch(error => reject(error));
    });
  }
};
const mutations = {
  setMetricsPreview(state, metrics) {
    state.metricsPreview = { ...state.metricsPreview, ...metrics };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
