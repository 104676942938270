import TopicModelingService from "@/API/services/TopicModelingService";

export const topicModelingRunRequestFactory = () => ({
  numberOfTopics: "5",
  numberOfTopWords: "10",
  numberOfTopDocuments: "10",
  dictionary: "",
  customStopwords: []
});

const state = {
  runRequestLastParams: null
};
const getters = {
  getRunRequestLastParams: ({ runRequestLastParams }) => runRequestLastParams
};
const actions = {
  run({ commit }, { data }) {
    commit("setRunRequestLastParams", data);
    return TopicModelingService.run(data);
  },
  getMetrics(_, projectId) {
    return TopicModelingService.getMetrics(projectId);
  },
  saveValues(_, { projectId, data }) {
    return TopicModelingService.saveValues(projectId, data);
  },
  uploadContentFile(_, { projectId, file }) {
    return TopicModelingService.uploadContentFile(projectId, file);
  },
  getAvailableLanguages() {
    return TopicModelingService.getAvailableLanguages();
  }
};
const mutations = {
  setRunRequestLastParams(state, params) {
    state.runRequestLastParams = params;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
