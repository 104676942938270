import { EventBus } from "@/prototypes/EventBus";

export const notifier = {
  notify: ({
    message,
    color = "info",
    timeout = "6000",
    x = "right",
    y = "bottom"
  }) => {
    EventBus.$emit("notifier-notify", { message, color, timeout, x, y });
  }
};
