import { getHttpClient } from "@/API/httpClient";

const httpClient = getHttpClient();

export default {
  login(credentials) {
    return httpClient.post("/login", credentials);
  },
  refreshToken(token) {
    return httpClient.post("/refresh", token);
  }
};
