import { EventBus } from "@/prototypes/EventBus";
import i18n from "@/i18n";

export const modals = {
  validation: ({ content, title, open = true }) => {
    EventBus.$emit("validation-modal-action", { content, title, open });
  },
  informative: ({ content, copyContent = false, open = true }) => {
    EventBus.$emit("informative-modal-action", { content, copyContent, open });
  },
  confirmation: ({
    action,
    cancelAction = null,
    content = i18n.t("COMMON.CONFIRMATION_CONTENT"),
    title = i18n.t("COMMON.CONFIRMATION_TITLE"),
    open = true
  }) => {
    EventBus.$emit("confirmation-modal-action", {
      open,
      content,
      title,
      action,
      cancelAction
    });
  }
};
