<template>
  <v-app id="app">
    <notifier />
    <informative-modal />
    <confirmation-modal />
    <validation-modal />
    <base-header v-if="!isRouteExcluded && isAuthorized" />
    <v-container
      fluid
      class="app-container"
      :class="['app-container', { 'default-background': !isRouteExcluded }]"
    >
      <navigation-menu v-if="!isRouteExcluded && isAuthorized" />

      <v-fade-transition hide-on-leave>
        <router-view />
      </v-fade-transition>
    </v-container>
  </v-app>
</template>
<script>
import BaseHeader from "@/components/Header/BaseHeader";
import Notifier from "@/components/Notifier";
import InformativeModal from "@/components/modals/InformativeModal";
import ConfirmationModal from "@/components/modals/ConfirmationModal";
import ValidationModal from "@/components/modals/ValidationModal";
import { mapState } from "vuex";
import NavigationMenu from "@/components/NavigationMenu";
export default {
  name: "App",
  components: {
    NavigationMenu,
    ValidationModal,
    ConfirmationModal,
    InformativeModal,
    BaseHeader,
    Notifier
  },
  data() {
    return {
      excluded: ["Login"]
    };
  },
  computed: {
    ...mapState({
      isAuthorized: state => state.auth.isAuthorized
    }),
    currentRoute() {
      return this.$route.name;
    },
    isRouteExcluded() {
      return this.excluded.includes(this.currentRoute);
    }
  }
};
</script>
<style lang="scss">
@import "./styles/globals";
.v-application {
  font-family: $body-font-family !important;

  .title {
    font-family: $body-font-family !important;
  }

  .caption {
    font-family: $body-font-family !important;
  }
}
.v-btn--disabled {
  pointer-events: unset !important;
  cursor: default;
}
.default-background {
  background-color: rgba(230, 230, 230, 0.98);
}
.app-container {
  height: 100%;
}
</style>
