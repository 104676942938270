import user from "@/router/panel/user";
import market from "@/router/panel/market";

export default [
  {
    path: "/panel",
    name: "Panel",
    redirect: "/panel/users",
    meta: {
      humanName: "Panel",
      roles: ["manager_local", "manager_global", "admin"]
    },
    component: () =>
      import(
        /* webpackChunkName: "panel-index" */ "@/views/Panel/views/Index.vue"
      ),
    children: [
      {
        path: "/panel/dashboard",
        name: "PanelDashboard",
        meta: {
          humanName: "Dashboard",
          roles: ["manager_local", "manager_global", "admin"]
        },
        component: () =>
          import(
            /* webpackChunkName: "panel-dashboard" */ "@/views/Panel/views/Dashboard/Index.vue"
          )
      },
      {
        path: "/panel/users",
        name: "PanelUsers",
        meta: {
          humanName: "Users",
          roles: ["manager_local", "manager_global", "admin"]
        },
        component: () =>
          import(
            /* webpackChunkName: "panel-users" */ "@/views/Panel/views/Users/Index.vue"
          ),
        children: [...user]
      },
      {
        path: "/panel/markets",
        name: "PanelMarkets",
        meta: {
          humanName: "Markets",
          roles: ["admin"]
        },
        component: () =>
          import(
            /* webpackChunkName: "panel-markets" */ "@/views/Panel/views/Markets/Index.vue"
          ),
        children: [...market]
      }
    ]
  }
];
