import { authenticationHeader, getHttpClient } from "@/API/httpClient";
const httpClient = getHttpClient();

export default {
  createDraft(marketId) {
    return httpClient.get(`/draft/${marketId}/create`, {
      headers: authenticationHeader()
    });
  },
  getDraft(draftId) {
    return httpClient.get(`/draft/${draftId}`, {
      headers: authenticationHeader()
    });
  },
  updateDraft(draftId, data) {
    return httpClient.post(`/draft/${draftId}`, data, {
      headers: authenticationHeader()
    });
  },
  searchBrand(term) {
    return httpClient.post(
      "/draft/search-brand",
      { term },
      {
        headers: authenticationHeader()
      }
    );
  },
  deleteDraft(draftId) {
    return httpClient.delete(`/draft/${draftId}`, {
      headers: authenticationHeader()
    });
  },
  uploadBrands(file) {
    return httpClient.post("/draft/upload-brands", file, {
      headers: {
        ...authenticationHeader(),
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });
  }
};
