import { authenticationHeader, getHttpClient } from "@/API/httpClient";
const httpClient = getHttpClient();

export default {
  exportDataToXlsx(projectId) {
    return httpClient.get(`/dashboard/${projectId}/export-data-to-xlsx`, {
      responseType: "blob",
      headers: {
        ...authenticationHeader()
      }
    });
  },
  getBarData(projectId, kind) {
    return httpClient.post(
      `/dashboard/widget/bar`,
      { projectId, kind },
      {
        headers: authenticationHeader()
      }
    );
  },
  getGridData(projectId, x, y) {
    return httpClient.post(
      `/dashboard/widget/grid`,
      { projectId, x, y },
      {
        headers: authenticationHeader()
      }
    );
  },
  getDonutData(projectId) {
    return httpClient.post(
      `/dashboard/widget/donut`,
      { projectId },
      {
        headers: authenticationHeader()
      }
    );
  },
  getTableData(projectId) {
    return httpClient.post(
      `/dashboard/widget/table`,
      { projectId },
      {
        headers: authenticationHeader()
      }
    );
  },
  exportXlsxDataTable(projectId) {
    return httpClient.get(`/dashboard/widget/table/${projectId}/export-xlsx`, {
      responseType: "blob",
      headers: {
        ...authenticationHeader()
      }
    });
  }
};
