import { authenticationHeader, getHttpClient } from "@/API/httpClient";

const httpClient = getHttpClient();

export default {
  createMarket(data) {
    return httpClient.post("/management/market/create", data, {
      headers: authenticationHeader()
    });
  },
  updateMarket(id, data) {
    return httpClient.patch(`/management/market/${id}`, data, {
      headers: authenticationHeader()
    });
  },
  getMarket(id) {
    return httpClient.get(`/management/market/${id}`, {
      headers: authenticationHeader()
    });
  },
  getMarkets() {
    return httpClient.get("/management/markets", {
      headers: authenticationHeader()
    });
  }
};
