<template>
  <v-navigation-drawer v-model="drawer" absolute temporary width="auto">
    <v-list-item>
      <v-list-item-avatar color="cyan lighten-4">
        <span class="white--text text-h5">{{ user.fullname[0] }}</span>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>{{ user.fullname }}</v-list-item-title>
        <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list>
      <v-list-item
        v-for="item in routes"
        :key="item.text"
        :to="item.to"
        :exact="item.exact"
        link
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <v-list-item link @click="handleLogout">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "NavigationMenu",
  data() {
    return {
      drawer: false,
      items: [
        {
          to: {
            name: "Home"
          },
          text: "Your projects",
          exact: true,
          icon: "mdi-view-list"
        },
        {
          to: {
            name: "LanguageAuditUpload"
          },
          text: "Language audit",
          icon: "mdi-file-upload-outline"
        },
        {
          to: {
            name: "KnowledgeBase"
          },
          text: "Knowledge base",
          icon: "mdi-book-open-page-variant"
        },
        {
          to: {
            name: "ContentrIndex"
          },
          text: "Contentr",
          icon: "mdi-view-dashboard-edit"
        },
        {
          to: {
            name: "Panel"
          },
          text: "Panel",
          icon: "mdi-shield-account",
          roles: ["manager_local", "manager_global", "admin"]
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser"
    }),
    routes() {
      return this.items.filter(
        item => !item.roles || item.roles.includes(this.user.role)
      );
    }
  },
  created() {
    this.$root.$on("open-navigation-menu", this.openDrawer);
  },
  beforeDestroy() {
    this.$root.$off("open-navigation-menu", this.openDrawer);
  },
  methods: {
    ...mapActions({
      logout: "auth/logout"
    }),
    openDrawer() {
      this.drawer = true;
    },
    handleLogout() {
      const needConfirmation = ["LanguageAudit", "Project"];
      if (needConfirmation.includes(this.$route.name)) {
        const action = () => {
          this.logout();
        };
        this.$modals.confirmation({
          action,
          title: this.$t("PROJECTS.FORM.LEAVE_PAGE_TITLE"),
          content: this.$t("PROJECTS.FORM.LEAVE_PAGE_CONTENT")
        });
      } else {
        this.logout();
      }
    }
  }
};
</script>
