import {
  prepareSaveProjectRequest,
  resolveAvailableTabs
} from "@/helpers/dataGenerationHelper";
import { stateArrayManager } from "@/store/stateManagementHelper";
import {
  pulsarApiFactory,
  pulsarManualFactory,
  stateFactory
} from "@/store/models/baseConfiguration";
import { notifier } from "@/prototypes/notifier";
import ProjectService from "@/API/services/ProjectService";

const state = {
  ...stateFactory()
};
const getters = {
  getSelectedDataSources: ({ selectedDataSources }) => selectedDataSources,
  getBrandwatchQueryIds: ({ brandwatchQueryString }) => {
    let match;
    if (brandwatchQueryString) {
      match = brandwatchQueryString.match(
        /app\.brandwatch\.com\/project\/(\d+)\/explore\/(\d+)/
      );
    }
    if (match) {
      match = {
        projectId: match[1],
        brandwatchId: match[2]
      };
    }
    return match;
  },
  getCurrentTab: ({ currentTab }) => currentTab,
  getAvailableTabs: ({ selectedDataSources }) =>
    resolveAvailableTabs(selectedDataSources),
  getBrands: ({ brands }) => brands,
  getCurrentlyEditedBrand: ({ currentlyEditedBrand }) => currentlyEditedBrand,
  isEditing: ({ currentlyEditedBrand, currentlyEditedTopic }) =>
    currentlyEditedBrand !== null || currentlyEditedTopic !== null
};
const actions = {
  saveDraft({ dispatch, state }, { draftId, projectSettings }) {
    const {
      brands,
      topics,
      externalBrands,
      brandwatchQueryString,
      selectedDataSources
    } = state;
    const draft = {
      brands,
      topics,
      externalBrands,
      brandwatchQueryString,
      selectedDataSources
    };
    const data = { ...projectSettings, draft };
    return new Promise((resolve, reject) => {
      dispatch("api/draft/updateDraft", { draftId, data }, { root: true })
        .then(async () => {
          await dispatch("api/project/getProjects", null, { root: true });
          resolve();
        })
        .catch(error => {
          notifier.notify({ message: error, color: "error" });
          reject();
        });
    });
  },
  getDraft({ commit, dispatch }, draftId) {
    return new Promise((resolve, reject) => {
      dispatch("api/draft/getDraft", draftId, { root: true })
        .then(({ data }) => {
          data.draft = data.draft ? data.draft : {};
          const {
            brands = [],
            externalBrands = {
              brands: [],
              file: null
            },
            topics = [],
            brandwatchQueryString = "",
            selectedDataSources = stateFactory().selectedDataSources
          } = data.draft;

          commit("setCurrentDraftData", data);
          commit("setBrands", brands);
          commit("setTopics", topics);
          commit("setExternalBrands", externalBrands);
          commit("setBrandwatchQueryString", brandwatchQueryString);
          commit("setSelectedDataSources", selectedDataSources);
          resolve();
        })
        .catch(error => {
          notifier.notify({ message: error, color: "error" });
          reject();
        });
    });
  },
  saveProject({ dispatch, state }, data) {
    const {
      brands,
      topics,
      selectedDataSources,
      externalBrands,
      brandwatchQueryString
    } = state;
    let params = {
      brands,
      topics,
      selectedDataSources,
      externalBrands,
      brandwatchQueryString
    };
    params = JSON.parse(JSON.stringify(params));
    const request = prepareSaveProjectRequest(
      { ...params },
      data.projectSettingsForm
    );

    return new Promise((resolve, reject) => {
      ProjectService.saveProject(data.draftId, request)
        .then(async response => {
          await dispatch("api/project/getProjects", null, { root: true });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
const mutations = {
  clearState(state) {
    const initialState = stateFactory();
    Object.keys(initialState).forEach(key => {
      state[key] = initialState[key];
    });
  },
  setBrandFormRef(state, ref) {
    state.brandFormRef = ref;
  },
  setCurrentDraftData(state, data) {
    state.currentDraftData = data;
  },
  setSelectedDataSources(state, dataSources) {
    state.selectedDataSources = dataSources;
  },
  setCurrentTab(state, currentTab) {
    state.currentTab = currentTab;
  },
  ...stateArrayManager({
    arrayName: "brands",
    set: "setBrands",
    add: "addBrand",
    update: "updateBrand",
    remove: "removeBrand"
  }),
  ...stateArrayManager({
    arrayName: "topics",
    set: "setTopics",
    add: "addTopic",
    update: "updateTopic",
    remove: "removeTopic"
  }),
  setExternalBrands(state, data) {
    state.externalBrands = data;
  },
  setBrandwatchQueryString(state, string) {
    state.brandwatchQueryString = string;
  },
  setCurrentlyEditedBrand(state, brand) {
    state.currentlyEditedBrand = brand;
  },
  setCurrentlyEditedTopic(state, topic) {
    state.currentlyEditedTopic = topic;
  },
  removeTopicsFromBrands(state) {
    state.brands = state.brands.map(brand => {
      brand.topics = {};

      return brand;
    });
  },
  removeTopicFromBrands(state, topic) {
    state.brands = state.brands.map(brand => {
      const brandHasTopic =
        brand.topics && Object.keys(brand.topics).includes(topic.localId);
      if (brandHasTopic) {
        delete brand.topics[topic.localId];
      }

      return brand;
    });
  },
  setPulsarTab(state, { brandId, tab }) {
    state.brands.map(brand => {
      if (brand.localId === brandId) {
        brand.pulsar.currentTab = tab;
      }

      return brand;
    });
  },
  removeBavOffline(state) {
    state.brands.map(brand => {
      brand.bavOffline = null;

      return brand;
    });
  },
  setBavOffline(state, bavOffline) {
    state.brands.map(brand => {
      if (brand.localId === state.currentlyEditedBrand.localId) {
        brand.bavOffline = bavOffline;
      }

      return brand;
    });
  },
  setPulsarManual(state, manual) {
    state.brands.map(brand => {
      if (brand.localId === state.currentlyEditedBrand.localId) {
        brand.pulsar = {
          ...brand.pulsar,
          manual,
          api: pulsarApiFactory()
        };
      }

      return brand;
    });
  },
  setPulsarApi(state, api) {
    state.brands.map(brand => {
      if (brand.localId === state.currentlyEditedBrand.localId) {
        brand.pulsar = {
          ...brand.pulsar,
          api,
          manual: pulsarManualFactory()
        };
      }

      return brand;
    });
  }
};

export const dataSources = {
  TOPICS_FROM_LANGUAGE_AUDIT: "Topics from language audit",
  BAV_OFFLINE: "BAV Offline",
  EXTERNAL_BRANDS: "External brands"
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
