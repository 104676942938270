export const theme = {
  themes: {
    light: {
      primary: "#2B3140",
      secondary: "#787F8D",
      success: "#009900",
      action: "#223E76",
      error: "#F63D45",
      warning: "#FFC107",
      background: "#3e485c"
    }
  }
};
