import panel from "@/router/panel/index";

export default [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "@/views/Home.vue")
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      preventAuthorizedUsers: true
    },
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/Login/Index.vue")
  },
  {
    path: "/processing/:projectId",
    name: "ProcessingData",
    component: () =>
      import(
        /* webpackChunkName: "processing" */ "@/views/ProcessingData/Index.vue"
      )
  },
  {
    path: "/project/:projectId/:draft?",
    name: "Project",
    component: () =>
      import(/* webpackChunkName: "project" */ "@/views/Project/Index.vue")
  },
  {
    path: "/language-audit-upload/",
    name: "LanguageAuditUpload",
    meta: {
      preventAuthorizedUsers: false
    },
    component: () =>
      import(
        /* webpackChunkName: "audit" */ "@/views/LanguageAuditUpload/Index.vue"
      )
  },
  {
    path: "/language-audit/",
    name: "LanguageAudit",
    meta: {
      preventAuthorizedUsers: false
    },
    component: () =>
      import(/* webpackChunkName: "audit" */ "@/views/LanguageAudit/Index.vue")
  },
  {
    path: "/data-preview/:projectId",
    name: "DataPreview",
    meta: {
      preventAuthorizedUsers: false
    },
    component: () =>
      import(
        /* webpackChunkName: "dataPreview" */ "@/views/DataPreview/Index.vue"
      )
  },
  {
    path: "/dashboard/:projectId",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard/Index.vue")
  },
  {
    path: "/contentr",
    name: "ContentrIndex",
    meta: {
      humanName: "Contentr"
    },
    component: () =>
      import(
        /* webpackChunkName: "contentr" */ "@/views/Contentr/views/Index.vue"
      )
  },
  {
    path: "/contentr-upload",
    name: "ContentrUpload",
    meta: {
      humanName: "Contentr Upload"
    },
    component: () =>
      import(
        /* webpackChunkName: "contentr-upload" */ "@/views/Contentr/views/ContentrUpload/Index"
      )
  },
  {
    path: "/contentr-project/:id",
    name: "ContentrProject",
    meta: {
      humanName: "Contentr Project"
    },
    component: () =>
      import(
        /* webpackChunkName: "contentr-project" */ "@/views/Contentr/views/ContentrProject/Index"
      )
  },
  {
    path: "/knowledge-base",
    name: "KnowledgeBase",
    meta: {
      humanName: "Knowledge Base"
    },
    component: () =>
      import(
        /* webpackChunkName: "knowledge-base" */ "@/views/KnowledgeBase/Index.vue"
      ),
    children: [
      {
        path: "/knowledge-base/:projectId",
        name: "KnowledgeBaseProject",
        meta: {
          humanName: "Project"
        },
        component: () =>
          import(
            /* webpackChunkName: "knowledge-base" */ "@/views/KnowledgeBase/Project.vue"
          )
      }
    ]
  },
  ...panel
];
