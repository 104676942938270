import { authenticationHeader, getHttpClient } from "@/API/httpClient";

const httpClient = getHttpClient();

export default {
  getProjects() {
    return httpClient.get("/dashboard/list", {
      headers: authenticationHeader()
    });
  },
  getProcessingStatus(projectId) {
    return httpClient.get(
      `/basic-config/${projectId}/check-processing-status`,
      {
        headers: authenticationHeader()
      }
    );
  },
  saveProject(draftId, draft) {
    return httpClient.post(`/basic-config/${draftId}/save`, draft, {
      headers: authenticationHeader()
    });
  },
  getTags(brandwatchProjectId, brandwatchQueryId, marketId) {
    return httpClient.get(
      `/draft/${marketId}/tags/${brandwatchProjectId}/${brandwatchQueryId}`,
      {
        headers: authenticationHeader()
      }
    );
  },
  getProject(projectId) {
    return httpClient.get(`/project/${projectId}`, {
      headers: authenticationHeader()
    });
  },
  deleteProject(projectId) {
    return httpClient.delete(`/project/${projectId}`, {
      headers: authenticationHeader()
    });
  }
};
