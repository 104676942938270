import Vue from "vue";
import Vuex from "vuex";

/**
 * modules
 */
import auth from "@/store/modules/auth";
import api from "@/store/modules/API";
import baseConfiguration from "@/store/modules/baseConfiguration";
import metrics from "@/store/modules/metrics";
import dashboards from "@/store/modules/dashboards";
import languageAudit from "@/store/modules/languageAudit";
import contentrProject from "@/store/modules/contentrProject";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    api,
    baseConfiguration,
    metrics,
    dashboards,
    languageAudit,
    contentrProject
  }
});
