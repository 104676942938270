import { authenticationHeader, getHttpClient } from "@/API/httpClient";

const httpClient = getHttpClient();

export default {
  getMetrics(projectId) {
    return httpClient.get(`basic-config/${projectId}/metrics-preview`, {
      headers: authenticationHeader()
    });
  },
  recalculateMetrics(projectId, data) {
    return httpClient.post(
      `/basic-config/${projectId}/recalculate-metrics`,
      data,
      {
        headers: {
          ...authenticationHeader(),
          "Content-Type": "application/json"
        }
      }
    );
  },
  recalculateAndSaveMetrics(projectId, data) {
    return httpClient.post(`/basic-config/${projectId}/save-metrics`, data, {
      headers: {
        ...authenticationHeader(),
        "Content-Type": "application/json"
      }
    });
  },
  saveMetrics(projectId) {
    return httpClient.post(`basic-config/${projectId}/metrics-preview`, null, {
      headers: authenticationHeader()
    });
  },
  getBrandwatchMetrics(projectId) {
    return httpClient.get(`/data-manual-fill/${projectId}`, {
      headers: authenticationHeader()
    });
  },
  saveBrandwatchMetrics(projectId, data) {
    return httpClient.post(`/data-manual-fill/${projectId}`, data, {
      headers: authenticationHeader()
    });
  }
};
