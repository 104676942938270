import jwtDecode from "jwt-decode";
import moment from "moment-timezone";
import store from "@/store";

export const getAccessToken = () => localStorage.getItem("accessToken");
export const getRefreshToken = () => localStorage.getItem("refreshToken");
export const getUser = () => JSON.parse(localStorage.getItem("user"));
export const setLoginToken = token => localStorage.setItem("loginToken", token);
export const setUser = user =>
  localStorage.setItem("user", JSON.stringify(user));

export function setLocalStorageTokens(tokens) {
  if (tokens.accessToken)
    localStorage.setItem("accessToken", tokens.accessToken);
  if (tokens.refreshToken)
    localStorage.setItem("refreshToken", tokens.refreshToken);
}

export function removeLocalStorageTokens() {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("loginToken");
  localStorage.removeItem("user");
}

export function checkTokenValidity(token) {
  try {
    if (!token) {
      return false;
    }
    let expToken = jwtDecode(token).exp;

    return expToken > moment().unix();
  } catch (e) {
    console.log(e);
    return false;
  }
}

export function getTimeDiff(exp) {
  if (!exp) return false;
  if (process.env.NODE_ENV === "development") {
    console.log({
      exp,
      moment: moment().unix(),
      s: exp - moment().unix()
    });
  }
  return (exp - moment().unix()) * 1000;
}

export async function initializationUserAuthentication() {
  if (checkTokenValidity(getAccessToken())) {
    const data = {
      accessToken: getAccessToken(),
      refreshToken: getRefreshToken()
    };
    store.commit("auth/setUser", getUser());
    return await store.dispatch("auth/authorize", data);
  } else {
    if (checkTokenValidity(getRefreshToken())) {
      return await store
        .dispatch("auth/refreshToken")
        .then(() => {
          store.commit("auth/setUser", getUser());
          return Promise.resolve();
        })
        .catch(e => {
          return Promise.reject(e);
        });
    }
  }
}
