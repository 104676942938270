<template>
  <div class="header-logo align-center">
    <v-img
      class="project-logo"
      contain
      max-height="60"
      max-width="60"
      src="@/assets/images/logo_bav.jpg"
    />
  </div>
</template>
<script>
export default {
  name: "BaseHeaderLogo"
};
</script>

<style lang="scss">
.header-logo {
  display: flex;
  flex: 0 0 auto;
}

.project-logo {
  display: flex;
  position: relative;
}
</style>
